import React from 'react';
import styled from 'styled-components';
import { Button } from '@makeship/core';
import { useIntersectionObserver } from '../../../../../hooks/useIntersectionObserver';
import { scrollToVariants } from '../../commonFunctions';
import config from '../../../../../../config.json';

const { elements } = config;

const AddToCartButtonFloatingWrapper = styled.div<{ shouldDisplay: boolean }>`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    align-items: center;
    display: ${({ shouldDisplay }) => (shouldDisplay ? 'flex' : 'block')};
    transition: height 500ms ease;
    overflow: hidden;
    height: ${({ shouldDisplay }) => (shouldDisplay ? '76px' : '0px')};
    width: 100%;
    position: fixed;
    bottom: -2px;
    right: 0px;
    z-index: 1;
    border: 2px solid ${({ theme }) => theme.colors.neutral1};
    padding: 0 4px;
    background-color: ${({ theme }) => theme.colors.neutral1};
  }
`;

const AddToCartButton = styled(Button.Primary)<{ uiDisabled: boolean }>`
  width: 100%;
  background-color: ${({ uiDisabled, theme }) =>
    uiDisabled ? theme.colors.neutral4 : theme.colors.primary} !important;

  &:hover {
    background-color: ${({ uiDisabled, theme }) => !uiDisabled && theme.colors.primaryDark} !important;
  }

  &:focus-visible {
    background-color: ${({ uiDisabled, theme }) => !uiDisabled && theme.colors.primaryDark} !important;
  }

  &:focus-visible:after {
    border: 2px solid ${({ uiDisabled, theme }) => !uiDisabled && theme.colors.neutral7} !important;
  }

  &:active {
    background-color: ${({ uiDisabled, theme }) => !uiDisabled && theme.colors.primary} !important;
  }
`;

const PledgeButtonBlocker = styled.div`
  position: absolute;
  width: 100%;
  height: 52px;
  cursor: pointer;
  border-radius: 8px;
`;

const AddToCartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

type AddToCartButtonFloatingProps = {
  shoppingCartRef: React.RefObject<HTMLDivElement>;
  isClothingWithSizes: boolean;
  hasSizeSelected: boolean;
  setError: (error: string | undefined) => void;
};

const AddToCartButtonFloating: React.FC<AddToCartButtonFloatingProps> = ({
  shoppingCartRef,
  isClothingWithSizes,
  hasSizeSelected,
  setError,
}: AddToCartButtonFloatingProps) => {
  const [isShoppingCartVisible] = useIntersectionObserver(shoppingCartRef, {
    root: null,
    rootMargin: '-64px 0px 0px 0px', // Set top margin to take into account navigation header
    threshold: 0.5,
  });

  return (
    <AddToCartButtonFloatingWrapper shouldDisplay={!isShoppingCartVisible}>
      <AddToCartWrapper>
        {isClothingWithSizes && !hasSizeSelected && (
          <PledgeButtonBlocker
            onClick={() => {
              scrollToVariants();
              setError('Please select a size!');
            }}
          />
        )}
        <AddToCartButton id={elements.pledgeButton} uiDisabled={false} data-native-pre-order-btn="Pledge $2 Today">
          Pledge $2 Today
        </AddToCartButton>
      </AddToCartWrapper>
    </AddToCartButtonFloatingWrapper>
  );
};

export default AddToCartButtonFloating;
