import React, { useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { Link as StyledLink } from '@makeship/core';
import posthog from 'posthog-js';
import { P2, S1, S2 } from '../Typography';
import EmptyRow from './empty-row';
import config from '../../../config.json';
import PetitionsSupportBoardModal from '../Modal/SupportBoardModal/petitionsSupportBoardModal';
import { ProductStage } from '../../types/common';
import { getProductIDFromShopifyGID, getStage } from '../../utils/product';
import { getProductFromContext } from '../../context/product';
import SupporterRow from './petitionSupporter-row';

const { routes } = config;

const SupportBoardWrapper = styled.div`
  padding-top: 0;
  margin-bottom: 0;
`;

const BadgeRows = styled.div`
  margin-top: 16px;
`;

const Footer = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 8px;
`;

const SupportBoardTitle = styled(S1)``;

const ViewAllLink = styled(StyledLink.Primary)``;

const ViewAllText = styled(S2)`
  color: ${({ theme }) => theme.colors.primary};
`;

const CreateAccountLink = styled(StyledLink.Primary)``;

const SupportBoardText = styled(P2)``;

type PetitionsSupportBoardProps = {
  petitionSupporters: Supporter[] | null;
};

const PetitionsSupportBoard: React.FC<PetitionsSupportBoardProps> = ({
  petitionSupporters,
}: PetitionsSupportBoardProps) => {
  const product = getProductFromContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const stage = getStage(product.tags);

  return (
    <>
      <SupportBoardWrapper>
        {stage === ProductStage.Petition ? (
          <SupportBoardText>
            Be an early supporter of this Petition and{' '}
            <Link href={routes.signup} passHref>
              <CreateAccountLink
                onClick={() =>
                  posthog.capture('pdp_badges_createAccount_click', {
                    productID: getProductIDFromShopifyGID(product.id),
                    productStage: stage,
                  })
                }
              >
                create an account
              </CreateAccountLink>
            </Link>{' '}
            to appear below.
          </SupportBoardText>
        ) : (
          <>
            <SupportBoardTitle>Petition Supporters</SupportBoardTitle>
            <SupportBoardText>
              Did you support this Petition?{' '}
              <Link
                onClick={() =>
                  posthog.capture('pdp_badges_createAccount_click', {
                    productID: getProductIDFromShopifyGID(product.id),
                    productStage: stage,
                  })
                }
                href={routes.signup}
                passHref
              >
                <StyledLink.Primary>Create an account</StyledLink.Primary>
              </Link>{' '}
              to appear below.
            </SupportBoardText>
          </>
        )}
        <BadgeRows>
          {petitionSupporters && petitionSupporters?.length > 0 ? (
            petitionSupporters.map((supporter) => <SupporterRow key={supporter.id} supporter={supporter} />)
          ) : (
            <EmptyRow hideImage />
          )}
        </BadgeRows>
        <Footer>
          {petitionSupporters && petitionSupporters?.length >= 3 && (
            <ViewAllLink>
              <ViewAllText
                onClick={() => {
                  posthog.capture('pdp_badges_viewAll_click', {
                    productID: getProductIDFromShopifyGID(product.id),
                    productStage: stage,
                  });
                  setIsModalOpen(true);
                }}
              >
                View All
              </ViewAllText>
            </ViewAllLink>
          )}
        </Footer>
      </SupportBoardWrapper>
      <PetitionsSupportBoardModal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
        product={product}
      />
    </>
  );
};

export default PetitionsSupportBoard;
