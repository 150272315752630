import React, { useEffect, useState } from 'react';
import { Link, utils } from '@makeship/core';
import StyledLink from '@makeship/core/lib/components/Link';
import styled from 'styled-components';
import posthog from 'posthog-js';

import { getProductFromContext } from '../../../../context/product';
import {
  parseMetafields,
  getStage,
  getCampaignStageTag,
  hasProductTag,
  getProductIDFromShopifyGID,
} from '../../../../utils/product';
import { S2, S2Styles, H8 } from '../../../Typography';
import { hasPetitionLaunched, scrollToPetitionProcess } from '../commonFunctions';
import { ProductStage, ProductType } from '../../../../types/common';
import PetitionRefundBlock from '../PetitionRefundBlock';
import PetitionLaunchInfo from '../PetitionLaunchInfo';
import PetitionProgressBar from '../PetitionProgressBar';
import LaunchedPetitionCTA from '../LaunchedPetitionCTA';
import ComingSoonPetitionExplanation from '../ComingSoonPetitionExplanation';
import { isUserLoggedIn, trackSubscribeButtonClicked } from '../../../../utils/analytics';
import { affectedPetitionsHandles } from '../../../../pages/petitions/[handle]';
import { useStore } from '../../../../store';

const SubscribeToLaunchButton = styled(Link.PrimaryButton)`
  background: linear-gradient(90deg, #ec399d 9.35%, #ba4bee 95.28%);
  color: ${({ theme }) => theme.colors.neutral1};
  border: 1px solid;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 16px 8px;

  &:hover {
    background: linear-gradient(90deg, #ec399d 9.35%, #ba4bee 95.28%);
  }
  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    position: relative;
    outline: none;
  }
  &:focus-visible:after {
    position: absolute;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    transform: translate(-50%, -50%);
    border: 2px solid ${({ theme }) => theme.colors.neutral1};
    border-radius: 3px;
    top: 50%;
    left: 50%;
    content: '';
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    -moz-box-shadow: inset 0px 4px 4px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
    -webkit-box-shadow: inset 0px 4px 4px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
    box-shadow: inset 0px 4px 4px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
    border: 1px solid transparent;
    outline: none;
  }
  &:active:after {
    border: none;
  }
`;

const SubscribeToPetitionLaunchButtonWrapper = styled.div`
  padding: 16px 18px;
`;

const SubscribeToPetitionLaunchButton = styled(Link.PrimaryButton)`
  background: linear-gradient(90deg, #ec399d 9.35%, #ba4bee 95.28%);
  color: ${({ theme }) => theme.colors.neutral1};
  border: 1px solid;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 14px 16px;

  &:active:after {
    border: none;
  }
  &:hover {
    background: linear-gradient(90deg, #ec399d 9.35%, #ba4bee 95.28%);
  }
`;

const PetitionLaunchWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

const PetitionLaunchText = styled(S2)`
  display: inline;
  padding-right: 4px;
`;

const PetitionLaunchDate = styled.p`
  ${S2Styles}
  font-weight: 500;
  letter-spacing: 0.64px;
  display: inline;
`;

const PetitionNextStepsLink = styled(StyledLink.Primary)`
  align-self: center;
  margin-left: 4px;
`;

const PetitionNextStepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 4px;
`;

type PetitionDetailsProps = {
  selectedVariant: Shopify.ProductVariant | undefined;
  setSelectedVariant: (variant: Shopify.ProductVariant | undefined) => void;
  totalInventory: number;
  moq: number;
  setIsModalOpen: (isOpen: boolean) => void;
  setIsEarlySupportersToggleActive: (isEarlySupportersToggleActive: boolean) => void;
};

const PetitionDetails: React.FC<PetitionDetailsProps> = ({
  selectedVariant,
  setSelectedVariant,
  totalInventory,
  moq,
  setIsModalOpen,
  setIsEarlySupportersToggleActive,
}: PetitionDetailsProps) => {
  const product = getProductFromContext();
  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);
  const isPetitionLaunched = hasPetitionLaunched(metafields.petitionLaunchDate);
  const hasManyVariants = product.variants.edges.length > 1;
  const isClothingWithSizes = product.productType === ProductType.Hoodie && hasManyVariants;
  const isAffectedPetition = affectedPetitionsHandles.includes(product.handle);

  const [hasSizeSelected, setHasSizeSelected] = useState(false);
  const [error, setError] = useState<string>();

  const { state } = useStore();

  const handleLearnMoreClick = () => {
    setIsEarlySupportersToggleActive(false);
    scrollToPetitionProcess();
    posthog.capture('petition_learnMoreLink_click', {
      product_id: getProductIDFromShopifyGID(product.id),
      product_title: product.title,
      product_stage: stage,
      is_logged_in: isUserLoggedIn(state.user),
    });
  };

  useEffect(() => {
    if (!isClothingWithSizes || selectedVariant) {
      setHasSizeSelected(true);
    }
  }, [selectedVariant, isClothingWithSizes]);

  useEffect(() => {
    setError(undefined);
    if (!hasManyVariants) {
      setSelectedVariant(product.variants.edges[0].node);
    } else {
      setSelectedVariant(undefined);
    }
  }, [product]);

  const createTags = (): string | undefined => {
    // We will almost always return this
    if (product.id) {
      return `${product.title}, ${getCampaignStageTag(stage, totalInventory, moq)}, subscribed-product`;
    }
    // We need this to handle empty tags
    return undefined;
  };

  const renderSubscribeButtonCTAText = () =>
    isPetitionLaunched ? 'Notify me when the Campaign launches' : 'Notify me when this Petition launches';

  return (
    <>
      <PetitionLaunchInfo />
      {isPetitionLaunched && !hasProductTag(product.tags, ProductStage.PetitionFailed) && (
        <PetitionProgressBar moq={moq} totalInventory={totalInventory} isAffectedPetition={isAffectedPetition} />
      )}
      {stage === ProductStage.PetitionFailed && <PetitionRefundBlock />}
      {!isPetitionLaunched && metafields.petitionLaunchDate && (
        <>
          <PetitionLaunchWrapper>
            <PetitionLaunchText>Petition Launches: </PetitionLaunchText>
            <PetitionLaunchDate>{utils.getDate(metafields.petitionLaunchDate)}</PetitionLaunchDate>
          </PetitionLaunchWrapper>
        </>
      )}
      {!isPetitionLaunched && stage !== ProductStage.PetitionSuccess && stage !== ProductStage.PetitionFailed && (
        <SubscribeToPetitionLaunchButton
          onClick={() => {
            setIsModalOpen(true);
            trackSubscribeButtonClicked(product, state.user, renderSubscribeButtonCTAText());
          }}
        >
          {renderSubscribeButtonCTAText()}
        </SubscribeToPetitionLaunchButton>
      )}
      {stage === ProductStage.PetitionSuccess && (
        <SubscribeToLaunchButton
          onClick={() => {
            setIsModalOpen(true);
            trackSubscribeButtonClicked(product, state.user, renderSubscribeButtonCTAText());
          }}
        >
          {renderSubscribeButtonCTAText()}
        </SubscribeToLaunchButton>
      )}
      {stage === ProductStage.PetitionSuccess && (
        <PetitionNextStepsWrapper>
          <PetitionNextStepsLink data-testid="petition-details-cta" onClick={handleLearnMoreClick}>
            Learn what&apos;s next below
          </PetitionNextStepsLink>
        </PetitionNextStepsWrapper>
      )}
      {stage === ProductStage.Petition && isPetitionLaunched && (
        <>
          {isPetitionLaunched ? (
            <LaunchedPetitionCTA
              selectedVariant={selectedVariant}
              setError={setError}
              hasSizeSelected={hasSizeSelected}
              setIsModalOpen={setIsModalOpen}
              handleLearnMoreClick={handleLearnMoreClick}
            />
          ) : (
            <ComingSoonPetitionExplanation />
          )}
        </>
      )}
    </>
  );
};

export default PetitionDetails;
