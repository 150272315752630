import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { utils } from '@makeship/core';
import { InView } from 'react-intersection-observer';

import { getSupportersByExternalProductId } from '../../../api/storefront/badge';
import Modal from '..';
import SupporterRow from '../../SupportBoard/petitionSupporter-row';
import Loader from '../../Loader';
import { H5, Overline } from '../../Typography';

import { getStage } from '../../../utils/product';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ProductStage, SortOrder } from '../../../types/common';
import { largeModalTheme } from '../../../styles/themes';
import { decryptShopifyId } from '../../../utils/id';

const ModalHeader = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: fixed;
  top: 0;
  width: 100%;
`;

const ModalWrapper = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: ${({ height }) => `${height}px`};
  }
`;

const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 192px);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-height: none;
    min-height: 0;
    flex: 1 1 0;
  }
`;

const Header = styled(H5)``;

const CancelIcon = styled.div`
  cursor: pointer;
`;

const SupportersHeader = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
`;

const SupporterText = styled(Overline)`
  margin-left: 16px;
  flex-basis: 10%;
  flex-grow: 1;
`;

const Supporters = styled.div`
  width: 100%;
  overflow-y: scroll;
  margin-top: 64px;
  padding: 0 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 0 8px;
  }
`;

const ModalFooter = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  box-shadow: 0px 2px 8px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: column;
    align-items: normal;
  }
`;

type PetitionsSupportBoardModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  product: Shopify.Product;
};

const PetitionsSupportBoardModal: React.FC<PetitionsSupportBoardModalProps> = ({
  isOpen,
  closeModal,
  product,
}: PetitionsSupportBoardModalProps) => {
  const [supporters, setSupporters] = useState<Supporter[]>([]);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<string>();
  const stage = getStage(product.tags);

  const size = useWindowSize();

  useEffect(() => {
    const sortOrder = stage === ProductStage.PetitionSuccess ? SortOrder.Ascending : SortOrder.Descending;
    setSupporters([]);
    setSortOrder(sortOrder);
    setCanLoadMore(true);
  }, [product]);

  const loadMore = () => {
    if (loading) return;

    setLoading(true);

    getSupportersByExternalProductId({
      externalProductId: decryptShopifyId(product.id).toString(),
      direction: sortOrder,
      offset: supporters.length.toString(),
      limit: (50).toString(),
    })
      .then(({ data }) => {
        if (!data) return;
        setSupporters(supporters.concat(data));
        if (data.length < 50) {
          setCanLoadMore(false);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} theme={largeModalTheme}>
      <ModalWrapper height={size.height}>
        <ModalHeader>
          <Header>Early Supporters</Header>
          <CancelIcon onClick={closeModal}>
            <Image src="/assets/icons/cancel-icon.svg" width="16" height="16" quality={90} />
          </CancelIcon>
        </ModalHeader>
        <ModalContent>
          <Supporters>
            <SupportersHeader>
              <SupporterText>Username</SupporterText>
            </SupportersHeader>
            {supporters.map((supporter) => (
              <SupporterRow supporter={supporter} />
            ))}
            {canLoadMore && (
              <InView as="div" onChange={(inView, entry) => inView && canLoadMore && loadMore()}>
                {canLoadMore && <Loader />}
              </InView>
            )}
          </Supporters>
        </ModalContent>
        <ModalFooter />
      </ModalWrapper>
    </Modal>
  );
};

export default PetitionsSupportBoardModal;
