import React from 'react';
import { utils } from '@makeship/core';
import styled from 'styled-components';
import posthog from 'posthog-js';
import { Element as ScrollElement } from 'react-scroll';
import { getProductFromContext } from '../../../../context/product';
import { getProductIDFromShopifyGID, getStage } from '../../../../utils/product';
import { P2, S2 } from '../../../Typography';

import PetitionProcess from '../PetitionProcess';
import { useStore } from '../../../../store';
import { getPDPTrackingLocation, handleSupportLinkTracking, isUserLoggedIn } from '../../../../utils/analytics';
import PetitionsSupportBoard from '../../../SupportBoard/petitionsSupportBoard';

const ProductDescription = styled.div`
  margin-top: 24px;

  * {
    max-width: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 32px;
  }
`;

const CreatorPitchWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  * {
    max-width: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const CreatorPitchToggle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  cursor: pointer;
`;

const ToggleTitle = styled(S2)<{ isActive?: boolean }>`
  width: 50%;
  text-align: center;
  margin-bottom: 24px;
  border-bottom: ${({ isActive, theme }) =>
    isActive
      ? `3px solid ${theme.colors.primary}`
      : `3px solid ${utils.hexToRGBA(theme.colors.primaryDarker, theme.alpha.light)}`};
  font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
  cursor: pointer;
`;

type ToggleItem = {
  toggleTitle: string;
  isEarlySupportersToggleActive: boolean;
};

const toggleItems: ToggleItem[] = [
  {
    toggleTitle: 'Early Supporters',
    isEarlySupportersToggleActive: true,
  },
  {
    toggleTitle: 'Details',
    isEarlySupportersToggleActive: false,
  },
];

type PetitionDescriptionProps = {
  isEarlySupportersToggleActive: boolean;
  setIsEarlySupportersToggleActive: (isEarlySupportersToggleActive: boolean) => void;
  petitionSupporters: Supporter[] | null;
};

const PetitionDescription: React.FC<PetitionDescriptionProps> = ({
  isEarlySupportersToggleActive,
  setIsEarlySupportersToggleActive,
  petitionSupporters,
}: PetitionDescriptionProps) => {
  const product = getProductFromContext();
  const stage = getStage(product.tags);
  const { state } = useStore();

  const handleToggleClick = (item: ToggleItem) => {
    setIsEarlySupportersToggleActive(item.isEarlySupportersToggleActive);
    posthog.capture('petition_infoToggle_click', {
      product_id: getProductIDFromShopifyGID(product.id),
      product_title: product.title,
      product_stage: stage,
      is_logged_in: isUserLoggedIn(state.user),
      toggle_title: item.toggleTitle,
    });
  };

  return (
    <ProductDescription>
      <>
        <CreatorPitchWrapper>
          <CreatorPitchToggle>
            {toggleItems.map((item) => (
              <ToggleTitle
                key={item.toggleTitle}
                isActive={
                  item.isEarlySupportersToggleActive ? isEarlySupportersToggleActive : !isEarlySupportersToggleActive
                }
                onClick={() => handleToggleClick(item)}
              >
                {item.toggleTitle}
              </ToggleTitle>
            ))}
          </CreatorPitchToggle>

          <div>
            <ScrollElement name="petition-process" />
            {isEarlySupportersToggleActive ? (
              <PetitionsSupportBoard petitionSupporters={petitionSupporters} />
            ) : (
              <PetitionProcess />
            )}
          </div>
        </CreatorPitchWrapper>
      </>
      <P2>
        For any questions, please contact{' '}
        <a
          href="https://support.makeship.com/hc/en-us/requests/new"
          onClick={() => {
            handleSupportLinkTracking('open_support_ticket', getPDPTrackingLocation(product), product);
          }}
        >
          Makeship Customer Support
        </a>
      </P2>
    </ProductDescription>
  );
};

export default PetitionDescription;
