import React from 'react';
import { utils } from '@makeship/core';
import styled from 'styled-components';
import { getProductFromContext } from '../../../../context/product';
import { getStage, parseMetafields } from '../../../../utils/product';
import { hasPetitionLaunched, renderLaunchDate } from '../commonFunctions';
import { S2 } from '../../../Typography';
import { ProductStage } from '../../../../types/common';

const PetitionLaunchInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
  justify-content: center;
`;

const PetitionLaunchInfoText = styled(S2)`
  margin-left: 8px;
  margin-top: 10px;
`;

const PetitionEndedInfoText = styled(S2)`
  margin-left: 8px;
  margin-bottom: 22px;
`;

const PetitionLaunchInfo: React.FC = () => {
  const product = getProductFromContext();
  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);

  return metafields.petitionEndDate && hasPetitionLaunched(metafields.petitionLaunchDate) ? (
    <PetitionLaunchInfoWrapper>
      {stage === ProductStage.PetitionFailed ? (
        <PetitionEndedInfoText>{`Petition Ended: ${utils.getDate(metafields.petitionEndDate)}`}</PetitionEndedInfoText>
      ) : (
        <>
          <PetitionLaunchInfoText>{`🚀 Est. Product Launch: ${renderLaunchDate(
            metafields.paymentDue,
            metafields.petitionEndDate,
          )}
      `}</PetitionLaunchInfoText>
        </>
      )}
    </PetitionLaunchInfoWrapper>
  ) : (
    <></>
  );
};

export default PetitionLaunchInfo;
