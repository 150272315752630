import React, { useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import styled, { ThemeContext } from 'styled-components';
import posthog from 'posthog-js';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { waitForElement } from '../../../../utils/dom';
import { createMultipassUrl } from '../../../../utils/url';
import { CartEventLocations, ProductStage, ProductType, SortOrder } from '../../../../types/common';
import {
  getProductIDFromShopifyGID,
  getStage,
  parseMetafields,
  updatePetitionFundedBar,
} from '../../../../utils/product';
import { useStore, State } from '../../../../store';
import { getProductDetailsFromContext, getProductFromContext } from '../../../../context/product';
import PetitionInfoHeader from '../PetitionInfoHeader';
import PetitionDetails from '../PetitionDetails';
import PetitionDescription from '../PetitionDescription';
import PetitionHoodieSizeGuide from '../PetitionHoodieSizeGuide';
import { isUserLoggedIn } from '../../../../utils/analytics';
import ComingSoonPetitionProcess from '../PetitionProcess/comingSoonPetitionProcess';
import CreatorMessage from '../../products/CreatorMessage';
import { createTags } from '../commonFunctions';
import { getSupportersByExternalProductId } from '../../../../api/storefront/badge';
import { decryptShopifyId } from '../../../../utils/id';

const LazySubscribeModal = dynamic(() => import('../../../Modal/SubscribeModal'), { ssr: false });

const PetitionInfoWrapper = styled.div`
  width: 100%;
  // Used to prevent layout shift from removal of elements
  min-height: 62.5vh;
`;

type PetitionInfoProps = {
  setProductImages: React.Dispatch<React.SetStateAction<Shopify.ImageEdge[]>>;
  selectedVariant?: Shopify.ProductVariant;
  setSelectedVariant: (variant: Shopify.ProductVariant | undefined) => void;
  hasPetitionLaunched: boolean;
  creatorCollectionHandle: string | null;
};

const PetitionInfo: React.FC<PetitionInfoProps> = ({
  setProductImages,
  selectedVariant,
  setSelectedVariant,
  hasPetitionLaunched,
  creatorCollectionHandle,
}: PetitionInfoProps) => {
  const product = getProductFromContext();
  const productDetails = getProductDetailsFromContext();
  const { moq, totalInventory, setTotalInventory } = productDetails;
  const moqNumber = Number(moq);
  const totalInventoryNumber = Number(totalInventory);
  const router = useRouter();
  const { state } = useStore();
  const [, setIsMobileViewport] = useState(false);
  const [, setNumber] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEarlySupportersToggleActive, setIsEarlySupportersToggleActive] = useState(true);
  const [petitionSupporters, setPetitionSupporters] = useState<Supporter[]>([]);

  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);
  const hasManyVariants = product.variants.edges.length > 1;
  const isClothingWithSizes = product.productType === ProductType.Hoodie && hasManyVariants;

  const size = useWindowSize();
  const theme = useContext(ThemeContext);

  const fetchAndSetPetitionSupporters = async () => {
    const sortOrder = stage === ProductStage.PetitionSuccess ? SortOrder.Ascending : SortOrder.Descending;
    try {
      // Fetch the initial badges
      const { data } = await getSupportersByExternalProductId({
        externalProductId: decryptShopifyId(product.id).toString(),
        direction: sortOrder,
        offset: '0',
        limit: '3',
      });

      // Update the state with the fetched badges
      setPetitionSupporters(data);
    } catch (error) {
      // Handle errors (e.g., update the state, show a message to the user)
      console.error('Failed to fetch initial supporters:', error);
    }
  };

  // For using our petitions 3rd party service
  useEffect(() => {
    if (!hasPetitionLaunched) return;
    const script = document.createElement('script');
    script.src = 'https://api.preproduct.io/preproduct-embed.js';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
    const onRouterChange = (newPath: string) => {
      window.location.href = router.basePath + newPath;
    };
    router.events.on('routeChangeStart', onRouterChange);

    return () => {
      document.body.removeChild(script);
      router.events.off('routeChangeStart', onRouterChange);
    };
  }, [hasPetitionLaunched, product, router]);

  const handleCheckoutUrl = (url: string, state: State) => {
    let newUrl = url;

    if (newUrl.includes('checkout.')) {
      const accessToken = state.user?.accessToken;
      const email = state.user?.customer?.email;

      if (accessToken && email) {
        newUrl = createMultipassUrl(url, email);
      }
    }

    return newUrl;
  };

  useEffect(() => {
    const saveOpen = window.open;

    Object.defineProperty(window, 'open', {
      value: (url = '', target?: string, features?: string) => {
        const newUrl = handleCheckoutUrl(url, state);

        if (saveOpen) {
          saveOpen.call(window, newUrl, target, features);
        }
      },
      configurable: true,
    });

    return () => {
      Object.defineProperty(window, 'open', { value: saveOpen, configurable: true });
    };
  }, [state.user?.customer]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const addToCartButtons = document.querySelectorAll('[data-native-pre-order-btn]');
      if (addToCartButtons.length > 1) {
        const stickyAddToCartButton = addToCartButtons.item(0);
        const regularAddToCartButton = addToCartButtons.item(1);

        const stickyClickHandler = () => {
          trackPetitionPledgeButton(CartEventLocations.StickyCartButton);
        };
        const regularClickHandler = () => {
          trackPetitionPledgeButton(CartEventLocations.ProductPage);
        };

        stickyAddToCartButton.addEventListener('click', stickyClickHandler);
        regularAddToCartButton.addEventListener('click', regularClickHandler);

        // Clean up the event listeners when the component unmounts
        return () => {
          stickyAddToCartButton.removeEventListener('click', stickyClickHandler);
          regularAddToCartButton.removeEventListener('click', regularClickHandler);
        };
      }
    }, 500);

    // Cleanup function to clear the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    waitForElement('#pp-variantfield-0').then((element: Element | null) => {
      if (!element) return;
      const selectElement = element as HTMLSelectElement;
      selectElement.autocomplete = 'off';
      const firstOption = selectElement.options[0];
      if (firstOption && !hasManyVariants) {
        firstOption.innerHTML = 'Product Concept';
      }
    });
  }, []);

  useEffect(() => {
    setupPetitionShippingDate();
  }, []);

  useEffect(() => {
    if (!size.width) {
      return;
    }
    // eslint-disable-next-line react/destructuring-assignment
    const isMobileViewport = size.width <= theme.breakpoints.mobile;
    setIsMobileViewport(isMobileViewport);
  }, [size]);

  useEffect(() => {
    setNumber(1);
    setPetitionSupporters([]);
    fetchAndSetPetitionSupporters();
  }, [product]);

  useEffect(() => {
    setTotalInventory(stage === ProductStage.PetitionFailed ? 0 : Math.abs(totalInventoryNumber || 0));

    if (selectedVariant) {
      const filteredImages = product.images.edges.filter(({ node }) =>
        hasManyVariants
          ? node.altText?.includes(selectedVariant?.title) || !node.altText
          : node.altText === selectedVariant.image?.altText,
      );
      setProductImages(filteredImages);
    }
  }, [selectedVariant]);

  useEffect(() => {
    updatePetitionFundedBar(stage, product, totalInventoryNumber, setTotalInventory);
  }, []);

  const setupPetitionShippingDate = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.addEventListener('ppCartSessionLaunched', (e: any) => {
      if (e?.target?.ppParser && metafields.delivery)
        e.target.ppParser.different_lead_time = formatPetitionProductDate(metafields.delivery);
    });
  };

  const formatPetitionProductDate = (dateStr: string): string => {
    const dateObj = new Date(dateStr);

    // Extract month and day
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    // Format the date
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  };

  const trackPetitionPledgeButton = (eventLocation: CartEventLocations) => {
    posthog.capture('petition_pledgeButton_click', {
      product_id: getProductIDFromShopifyGID(product.id),
      product_title: product.title,
      product_stage: stage,
      creator: metafields.creator,
      price: product.priceRange.minVariantPrice.amount,
      event_location: eventLocation,
      is_logged_in: isUserLoggedIn(state.user),
    });
  };

  return (
    <PetitionInfoWrapper>
      <PetitionInfoHeader creatorCollectionHandle={creatorCollectionHandle} />
      {stage !== ProductStage.Demo && (
        <PetitionDetails
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          totalInventory={totalInventoryNumber}
          moq={moqNumber}
          setIsModalOpen={setIsModalOpen}
          setIsEarlySupportersToggleActive={setIsEarlySupportersToggleActive}
        />
      )}
      <CreatorMessage />
      {hasPetitionLaunched && stage !== ProductStage.PetitionFailed && (
        <PetitionDescription
          isEarlySupportersToggleActive={isEarlySupportersToggleActive}
          setIsEarlySupportersToggleActive={setIsEarlySupportersToggleActive}
          petitionSupporters={petitionSupporters}
        />
      )}
      {/* Displays Infographic for ComingSoon Petitions */}
      {stage !== ProductStage.Demo && stage !== ProductStage.PetitionFailed && !hasPetitionLaunched && (
        <ComingSoonPetitionProcess />
      )}

      {isClothingWithSizes && <PetitionHoodieSizeGuide />}
      <LazySubscribeModal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
        tags={createTags(product, totalInventoryNumber, moqNumber)}
        description={
          hasPetitionLaunched
            ? 'Get notified when this idea launches a 3-week campaign with a real prototype!'
            : 'Get notified when this Petition concept launches!'
        }
      />
    </PetitionInfoWrapper>
  );
};

export default PetitionInfo;
