import React from 'react';
import styled from 'styled-components';
import { BadgeText, ProductStage } from '../../../../types/common';
import { getProductFromContext } from '../../../../context/product';
import { getStage, hasProductTag, parseMetafields } from '../../../../utils/product';
import Badge from '../../../Badge';
import { Caption, S1Styles } from '../../../Typography';
import { hasPetitionLaunched } from '../commonFunctions';

const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PetitionBadge = styled(Badge)<{ stage: ProductStage }>`
  text-transform: initial;
  background-color: ${({ theme, stage }) => {
    switch (stage) {
      case ProductStage.Petition:
        return theme.colors.prelaunchPurple;
      case ProductStage.PetitionSuccess:
        return theme.colors.periwinkle;
      case ProductStage.PetitionFailed:
        return theme.colors.neutral2;
      case ProductStage.ComingSoon:
        return theme.colors.blurple;
    }
  }};
  ${Caption} {
    ${S1Styles}
    color: ${({ theme, stage }) => {
      switch (stage) {
        case ProductStage.Petition:
        case ProductStage.ComingSoon:
          return theme.colors.neutral1;
        case ProductStage.PetitionSuccess:
          return theme.colors.neutral7;
        case ProductStage.PetitionFailed:
          return theme.colors.error;
      }
    }};
  }
  margin: auto;
`;

const renderPetitionBadge = (product: Shopify.Product): React.ReactNode => {
  const isPetition =
    hasProductTag(product.tags, ProductStage.Petition) ||
    hasProductTag(product.tags, ProductStage.PetitionSuccess) ||
    hasProductTag(product.tags, ProductStage.PetitionFailed);
  const stage = getStage(product.tags);
  const metafields = parseMetafields(product.metafields);
  const launchDate = metafields.petitionLaunchDate;
  const isLaunched = hasPetitionLaunched(launchDate);

  if (isPetition) {
    switch (true) {
      case !isLaunched:
        return <PetitionBadge text={BadgeText.ComingSoon} isActive stage={ProductStage.ComingSoon} />;
      case stage === ProductStage.Petition:
        return <PetitionBadge text={BadgeText.Concept} isActive stage={stage} />;
      case stage === ProductStage.PetitionSuccess:
        return <PetitionBadge text={BadgeText.GoalReached} isActive stage={stage} />;
      case stage === ProductStage.PetitionFailed:
        return <PetitionBadge text={BadgeText.GoalNotReached} isActive stage={stage} />;
      default:
        return <></>;
    }
  }
};

const PetitionHeader: React.FC = () => {
  const product = getProductFromContext();

  return <ProductRow>{renderPetitionBadge(product)}</ProductRow>;
};

export default PetitionHeader;
