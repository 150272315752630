import React from 'react';
import styled from 'styled-components';
import StyledLink from '@makeship/core/lib/components/Link';
import posthog from 'posthog-js';
import { ProductStage } from '../../../../types/common';

import { getProductFromContext } from '../../../../context/product';
import {
  getEvergreenInventoryState,
  getProductIDFromShopifyGID,
  getStage,
  hasProductTag,
  parseMetafields,
} from '../../../../utils/product';
import { H5, S2 } from '../../../Typography';
import PetitionHeader from '../PetitionHeader';

const PetitionHeaderWrapper = styled.div`
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: none;
  }
`;

const PetitionDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 0px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    padding: 40px 0px;
  }
`;

const PetitionTitle = styled(H5)`
  text-align: center;
`;

const CreatorName = styled(S2)`
  margin-top: 4px;
  text-align: center;
`;

type PetitionInfoHeaderProps = {
  creatorCollectionHandle: string | null;
};

const PetitionInfoHeader: React.FC<PetitionInfoHeaderProps> = ({
  creatorCollectionHandle,
}: PetitionInfoHeaderProps) => {
  const product = getProductFromContext();
  const metafields = parseMetafields(product.metafields);

  const renderCollectionLink = () => {
    if (creatorCollectionHandle) {
      return (
        <CreatorName>
          <StyledLink.Primary
            onClick={() =>
              posthog.capture('pdp_creatorLink_click', {
                productID: getProductIDFromShopifyGID(product.id),
                creatorName: metafields.creator,
                productStage: getStage(product.tags),
                linkHref: `/shop/creator/${creatorCollectionHandle}`,
                evergreen_inventory_state: getEvergreenInventoryState(product),
              })
            }
            href={`/shop/creator/${creatorCollectionHandle}`}
          >
            By: {metafields.creator}
          </StyledLink.Primary>
        </CreatorName>
      );
    }
    return <CreatorName>By: {metafields.creator}</CreatorName>;
  };

  const renderPetitionTitle = () => {
    if (hasProductTag(product.tags, ProductStage.PetitionSuccess)) {
      return <PetitionTitle>You did it! {product.title} is happening!</PetitionTitle>;
    }
    if (hasProductTag(product.tags, ProductStage.Petition)) {
      return <PetitionTitle>Help bring {product.title} to life!</PetitionTitle>;
    }
    return <PetitionTitle>{product.title}</PetitionTitle>;
  };

  return (
    <>
      <PetitionHeaderWrapper>
        <PetitionHeader />
      </PetitionHeaderWrapper>
      <PetitionDetails>
        {renderPetitionTitle()}
        {metafields.creator && renderCollectionLink()}
      </PetitionDetails>
    </>
  );
};

export default PetitionInfoHeader;
