import React, { useContext, useEffect, useState } from 'react';
import Image from 'next/image';
import posthog from 'posthog-js';
import { utils } from '@makeship/core';
import StyledLink from '@makeship/core/lib/components/Link';
import { Element as ScrollElement } from 'react-scroll';
import styled, { ThemeContext } from 'styled-components';
import { getProductFromContext } from '../../../../context/product';
import { getProductIDFromShopifyGID, parseMetafields } from '../../../../utils/product';
import { S1, Overline, P2, Caption } from '../../../Typography';
import { renderLaunchDate } from '../commonFunctions';
import PetitionHelpGuideModal from '../../../Modal/PetitionHelpGuideModal';
import { handleHelpGuideModalClick, isUserLoggedIn } from '../../../../utils/analytics';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useStore } from '../../../../store';

const PetitionProcessContainer = styled.div`
  padding-bottom: 24px;
  position: relative;
`;

const PetitionInfoHeader = styled(S1)`
  margin-bottom: 8px;
`;

const PetitionInfographicWrapper = styled.div`
  display: flex;
`;

const InfographicLogoWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: 8px 16px 0 0;
`;

const PetitionInfographicDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
`;

const PetitionHelpText = styled(P2)`
  display: inline;
`;

const RefundsAndReturnsTextWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 6px;
`;

const PetitionInfographicItem = styled.div<{ marginBottom?: string; height?: string | null }>`
  ${({ height }) => height && `height: ${height}px;`}
  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
  &:first-child {
    margin-top: 4px;
  }
  &:last-child {
    margin-top: -24px;
  }

  & > ${Overline} {
    color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  }
`;

const Anchor = styled.span`
  display: block;
  position: absolute;
  top: -70px;
  visibility: hidden;
`;

const PetitionProcess: React.FC = () => {
  const product = getProductFromContext();
  const metafields = parseMetafields(product.metafields);
  const [isHelpGuideModalOpen, setIsHelpGuideOpen] = React.useState(false);

  const [isMobileViewport, setIsMobileViewport] = useState<boolean>(false);
  const size = useWindowSize();
  const theme = useContext(ThemeContext);

  const { state } = useStore();

  useEffect(() => {
    if (!size.width) {
      return;
    }
    // eslint-disable-next-line react/destructuring-assignment
    const isMobileViewport = size.width <= theme.breakpoints.mobile;
    setIsMobileViewport(isMobileViewport);
  }, [size]);

  return (
    <PetitionProcessContainer>
      <ScrollElement name="petition-process" />
      <Anchor id="shipping" />
      <PetitionInfoHeader data-testid="petition-infographic-header">Petition Process</PetitionInfoHeader>
      <PetitionInfographicWrapper>
        <InfographicLogoWrapper>
          <Image src="/assets/petition-infographic.png" alt="Infographic Icon" width={16} height={477} layout="fixed" />
        </InfographicLogoWrapper>
        <PetitionInfographicDetails>
          <PetitionInfographicItem marginBottom="52" height="24">
            <S1 data-testid="petition-infographic-item-petition-header">Petition</S1>
            {metafields.petitionEndDate && (
              <>{metafields.petitionEndDate && <P2>Ends: {utils.getDate(metafields.petitionEndDate)}</P2>}</>
            )}
          </PetitionInfographicItem>
          <PetitionInfographicItem marginBottom={isMobileViewport ? '14' : '38'}>
            <S1>Prototype</S1>
            <P2>Petition enters design process to become a real product</P2>
          </PetitionInfographicItem>
          <PetitionInfographicItem marginBottom="42" height="78">
            <S1>Live Campaign</S1>
            {metafields.petitionLaunchDate && (
              <P2>Launches: {renderLaunchDate(metafields.paymentDue, metafields.petitionEndDate)}</P2>
            )}
            <P2>Product is available for a 3 week limited time</P2>
          </PetitionInfographicItem>
          <PetitionInfographicItem marginBottom="42" height="22">
            <S1>Production</S1>
          </PetitionInfographicItem>
          <PetitionInfographicItem marginBottom="44">
            <S1>Shipment</S1>
            <Overline>
              Delivery takes 7-15 business days.
              <br /> *Delivery to some areas may take <br />
              10-30 business days
            </Overline>
          </PetitionInfographicItem>
          <PetitionInfographicItem marginBottom="18">
            <S1>Delivery</S1>
          </PetitionInfographicItem>
        </PetitionInfographicDetails>
      </PetitionInfographicWrapper>
      <StyledLink.Primary onClick={() => handleHelpGuideModalClick(product, setIsHelpGuideOpen)}>
        Click here
      </StyledLink.Primary>
      <PetitionHelpText> to learn more about Petitions.</PetitionHelpText>
      <RefundsAndReturnsTextWrapper>
        <Caption>Returns and Refunds</Caption>
        <P2>
          All sales are final and non-refundable. If a Campaign does not achieve its funding goal, all orders will be
          refunded within 4-7 business days.
        </P2>
      </RefundsAndReturnsTextWrapper>

      <PetitionHelpText
        onClick={() => {
          posthog.capture('petition_learnMoreLink_click', {
            product_id: getProductIDFromShopifyGID(product.id),
            product_title: product.title,
            product_tags: product.tags,
            is_logged_in: isUserLoggedIn(state.user),
          });
        }}
      >
        Want more information?{' '}
      </PetitionHelpText>
      <a
        href="https://support.makeship.com/hc/en-us/articles/29429491310477-What-is-the-difference-between-a-petition-and-a-campaign"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledLink.Primary>Learn more</StyledLink.Primary>
      </a>
      <PetitionHelpGuideModal isOpen={isHelpGuideModalOpen} closeModal={() => setIsHelpGuideOpen(false)} />
    </PetitionProcessContainer>
  );
};

export default PetitionProcess;
