import React, { useRef } from 'react';
import { getProductFromContext } from '../../../../context/product';
import { ProductType } from '../../../../types/common';
import AddToCartButtonFloating from './AddToCartButtonFloating';
import AddToCart from './AddToCartButton';

type AddToCartPetitionsProps = {
  hasSizeSelected: boolean;
  setError: (error: string | undefined) => void;
  setIsModalOpen: (isOpen: boolean) => void;
};

const AddToCartPetitions: React.FC<AddToCartPetitionsProps> = ({
  hasSizeSelected,
  setError,
  setIsModalOpen,
}: AddToCartPetitionsProps) => {
  const product = getProductFromContext();
  const hasManyVariants = product.variants.edges.length > 1;
  const isClothingWithSizes = product.productType === ProductType.Hoodie && hasManyVariants;

  const shoppingCartRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <AddToCartButtonFloating
        shoppingCartRef={shoppingCartRef}
        isClothingWithSizes={isClothingWithSizes}
        hasSizeSelected={hasSizeSelected}
        setError={setError}
      />
      <AddToCart
        shoppingCartRef={shoppingCartRef}
        isClothingWithSizes={isClothingWithSizes}
        hasSizeSelected={hasSizeSelected}
        setError={setError}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default AddToCartPetitions;
