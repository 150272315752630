import React from 'react';
import styled from 'styled-components';
import StyledLink from '@makeship/core/lib/components/Link';
import { getProductFromContext } from '../../../../context/product';
import { parseMetafields } from '../../../../utils/product';
import { renderLaunchDate } from '../commonFunctions';
import { P2, S2 } from '../../../Typography';
import { getRemainingAmountAfterPledge } from '../../../../utils/accounting';

const PetitionExplanationWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background4};
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 32px;
`;

const PetitionExplanationText = styled(P2)`
  margin-bottom: 4px;
`;

type PetitionExplanationProps = {
  handleLearnMoreClick: (isWhyToggleActive: boolean) => void;
};

const PetitionExplanation: React.FC<PetitionExplanationProps> = ({
  handleLearnMoreClick,
}: PetitionExplanationProps) => {
  const product = getProductFromContext();
  const metafields = parseMetafields(product.metafields);

  const handleLearnMoreButtonClick = () => {
    handleLearnMoreClick(false);
  };

  return (
    <PetitionExplanationWrapper>
      <S2>⭐️ What is a pledge?</S2>
      <PetitionExplanationText>
        Making a pledge means you <strong>pay $2.00 now</strong> and we&apos;ll charge the remaining $
        {getRemainingAmountAfterPledge(product.variants.edges[0].node.priceV2?.amount)} (+ shipping and tax) when the
        product launches <strong>{renderLaunchDate(metafields.paymentDue, metafields.petitionEndDate)}</strong>. If the
        petition fails, you&apos;ll get a refund.
      </PetitionExplanationText>
      <StyledLink.Primary onClick={handleLearnMoreButtonClick}>Learn more</StyledLink.Primary>
    </PetitionExplanationWrapper>
  );
};

export default PetitionExplanation;
