import React from 'react';
import styled from 'styled-components';
import { hasPetitionLaunched } from '../commonFunctions';
import CountdownTimer from '../../../CountdownTimer';
import { S2 } from '../../../Typography';
import { getProductFromContext } from '../../../../context/product';
import { getStage } from '../../../../utils/product';
import { ProductStage } from '../../../../types/common';

const PetitionCountdownTimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  min-height: 22px;
`;

type PetitionCountdownTimerProps = {
  launchDate: string | undefined;
  endDate: string;
};

const PetitionCountdownTimer: React.FC<PetitionCountdownTimerProps> = ({
  launchDate,
  endDate,
}: PetitionCountdownTimerProps) => {
  const product = getProductFromContext();
  const stage = getStage(product.tags);

  return (
    <PetitionCountdownTimerWrapper data-testid="petition-countdown-title">
      {stage === ProductStage.Petition &&
        (launchDate && !hasPetitionLaunched(launchDate) ? (
          <>
            <S2>Petition launches in...</S2>
            <CountdownTimer date={launchDate} />
          </>
        ) : (
          <>
            <S2>Help bring this idea to life!</S2>
            <CountdownTimer date={endDate} />
          </>
        ))}
    </PetitionCountdownTimerWrapper>
  );
};
export default PetitionCountdownTimer;
