import React from 'react';
import Image from 'next/image';
import { utils } from '@makeship/core';
import { Element as ScrollElement } from 'react-scroll';
import styled from 'styled-components';
import { S1, Overline } from '../../../Typography';

const PetitionProcessContainer = styled.div`
  padding-bottom: 24px;
  position: relative;
`;

const PetitionInfoHeader = styled(S1)`
  margin-bottom: 8px;
`;

const PetitionInfographicWrapper = styled.div`
  display: flex;
`;

const InfographicLogoWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: 8px 16px 0 0;
`;

const PetitionInfographicDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
`;

const PetitionInfographicItem = styled.div<{ marginBottom?: string; height?: string | null }>`
  ${({ height }) => height && `height: ${height}px;`}
  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
  &:first-child {
    margin-top: 4px;
  }
  &:last-child {
    margin-top: -24px;
  }

  & > ${Overline} {
    color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  }
`;

const Anchor = styled.span`
  display: block;
  position: absolute;
  top: -70px;
  visibility: hidden;
`;

const ComingSoonPetitionProcess: React.FC = () => (
  <PetitionProcessContainer>
    <ScrollElement name="petition-process" />
    <Anchor id="shipping" />
    <PetitionInfoHeader data-testid="petition-infographic-header">Petition Process</PetitionInfoHeader>
    <PetitionInfographicWrapper>
      <InfographicLogoWrapper>
        <Image
          src="/assets/comingsoon-petition-infographic.png"
          alt="Infographic Icon"
          width={17}
          height={374}
          layout="fixed"
        />
      </InfographicLogoWrapper>
      <PetitionInfographicDetails>
        <PetitionInfographicItem marginBottom="60" height="4">
          <S1 data-testid="petition-infographic-item-petition-header">Petition</S1>
        </PetitionInfographicItem>
        <PetitionInfographicItem data-testid="petition-infographic-item-petition-text" marginBottom="40">
          <S1>Prototype</S1>
        </PetitionInfographicItem>
        <PetitionInfographicItem marginBottom="42" height="24">
          <S1>Live Campaign</S1>
        </PetitionInfographicItem>
        <PetitionInfographicItem marginBottom="42" height="22">
          <S1>Production</S1>
        </PetitionInfographicItem>
        <PetitionInfographicItem marginBottom="30">
          <S1>Shipment</S1>
          <Overline>
            Delivery takes 7-15 business days.
            <br /> *Delivery to some areas may take <br />
            10-30 business days
          </Overline>
        </PetitionInfographicItem>
        <PetitionInfographicItem marginBottom="18">
          <S1>Delivery</S1>
        </PetitionInfographicItem>
      </PetitionInfographicDetails>
    </PetitionInfographicWrapper>
  </PetitionProcessContainer>
);

export default ComingSoonPetitionProcess;
