import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { getProductFromContext } from '../../../../context/product';
import Carousel from '../../../Carousel';
import ImageGallery from '../../../ImageGallery';

const PetitionCarouselWrapper = styled.div`
  flex: 1 1;
  padding-right: 40px;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    padding-right: 40px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    padding-right: 0;
  }
`;

const ImageGalleryWrapper = styled.div`
  display: block;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 112px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: none;
  }
`;

const ImageCarouselWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: block;
  }
`;

const ImageWrapper = styled(Image)`
  width: 100%;
  object-fit: contain;
`;

type PetitionCarouselProps = {
  productImages: Shopify.ImageEdge[];
};

const PetitionCarousel: React.FC<PetitionCarouselProps> = ({ productImages }: PetitionCarouselProps) => {
  const product = getProductFromContext();

  const renderCarouselCards = (productImages: Shopify.ImageEdge[]): JSX.Element[] => {
    const elements: JSX.Element[] = productImages.map((edge, index) => (
      <ImageWrapper
        key={edge.node.transformedSrc}
        src={edge.node.transformedSrc}
        alt={edge.node.altText || 'product image'}
        width={1000}
        height={1000}
        quality={90}
        priority={index === 0}
      />
    ));

    return elements;
  };

  return (
    <PetitionCarouselWrapper>
      <ImageGalleryWrapper>
        <ImageGallery
          images={productImages.map((edge): Shopify.Image => edge.node)}
          images360={[]}
          product={product}
          isPetition
        />
      </ImageGalleryWrapper>
      <ImageCarouselWrapper>
        <Carousel cardNumber={1} product={product} isPetition>
          {productImages.length > 0
            ? renderCarouselCards(productImages)
            : [
                <ImageWrapper
                  key="/assets/default_product_asset.png"
                  src="/assets/default_product_asset.png"
                  alt="product image"
                  width={1000}
                  height={1000}
                  quality={90}
                />,
              ]}
        </Carousel>
      </ImageCarouselWrapper>
    </PetitionCarouselWrapper>
  );
};

export default PetitionCarousel;
