import React from 'react';
import Image from 'next/image';
import { Element as ScrollElement } from 'react-scroll';
import styled from 'styled-components';
import { H5 } from '../../../Typography';

const HoodieSizeGuideWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 524px;
`;

const PetitionHoodieSizeGuide: React.FC = () => (
  <>
    <ScrollElement name="size-guide" />
    <HoodieSizeGuideWrapper>
      <H5>Size Guide</H5>
      <Image src="/assets/hoodie-size-guide.png" alt="Hoodie Size Guide" width={913} height={409} />
    </HoodieSizeGuideWrapper>
  </>
);

export default PetitionHoodieSizeGuide;
