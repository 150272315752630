import React from 'react';
import styled from 'styled-components';
import StyledLink from '@makeship/core/lib/components/Link';
import posthog from 'posthog-js';
import { getProductFromContext } from '../../../../context/product';
import { getProductIDFromShopifyGID, getStage, parseMetafields } from '../../../../utils/product';
import { renderLaunchDate, scrollToPetitionProcess } from '../commonFunctions';
import { P2 } from '../../../Typography';
import { isUserLoggedIn } from '../../../../utils/analytics';
import { useStore } from '../../../../store';
import { getRemainingAmountAfterPledge } from '../../../../utils/accounting';

const ComingSoonPetitionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  row-gap: 12px;
`;

const PetitionExplanationWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background4};
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 32px;
`;

const PetitionExplanationText = styled(P2)`
  margin-bottom: 4px;
`;

const ComingSoonPetitionExplanation: React.FC = () => {
  const product = getProductFromContext();
  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);
  const { state } = useStore();

  const handleLearnMoreClick = () => {
    scrollToPetitionProcess();
    posthog.capture('petition_learnMoreLink_click', {
      product_id: getProductIDFromShopifyGID(product.id),
      product_title: product.title,
      product_stage: stage,
      is_logged_in: isUserLoggedIn(state.user),
    });
  };

  return (
    <ComingSoonPetitionWrapper>
      <PetitionExplanationWrapper>
        <PetitionExplanationText>
          Making a pledge means you <strong>Pay $2.00</strong> and we&apos;ll charge the remaining $
          {getRemainingAmountAfterPledge(product.variants.edges[0].node.priceV2?.amount)} + shipping and tax when the
          product launches {renderLaunchDate(metafields.paymentDue, metafields.petitionEndDate)}. If the petition fails,
          you&apos;ll get a refund.
        </PetitionExplanationText>
        <StyledLink.Primary onClick={handleLearnMoreClick}>Learn more below</StyledLink.Primary>
      </PetitionExplanationWrapper>
    </ComingSoonPetitionWrapper>
  );
};

export default ComingSoonPetitionExplanation;
