import React from 'react';
import styled from 'styled-components';
import { getProductIDFromShopifyGID, getProductVariantIDFromShopifyGID } from '../../../../utils/product';
import { getProductFromContext } from '../../../../context/product';
import { parseForShopifyDomain } from '../../../../utils/petition';
import AddToCartPetitions from '../AddToCartPetitions';
import PetitionExplanation from '../PetitionExplanation';

const PreProductPledgeBadge = styled.div`
  text-align: left;
  padding: 10px 0px;
`;

type LaunchedPetitionCTAProps = {
  selectedVariant: Shopify.ProductVariant | undefined;
  setError: (error: string | undefined) => void;
  hasSizeSelected: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  handleLearnMoreClick: (isWhyToggleActive: boolean) => void;
};

const LaunchedPetitionCTA: React.FC<LaunchedPetitionCTAProps> = ({
  selectedVariant,
  setError,
  hasSizeSelected,
  setIsModalOpen,
  handleLearnMoreClick,
}: LaunchedPetitionCTAProps) => {
  const product = getProductFromContext();

  return (
    <>
      {selectedVariant && product && (
        <>
          <PreProductPledgeBadge
            id="preproduct-pledge"
            data-id={getProductIDFromShopifyGID(product.id)}
            data-starting-variant={getProductVariantIDFromShopifyGID(selectedVariant.id)}
            data-headless="true"
            data-domain={
              process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_URL &&
              parseForShopifyDomain(process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_URL)
            }
          />
          <AddToCartPetitions hasSizeSelected={hasSizeSelected} setError={setError} setIsModalOpen={setIsModalOpen} />
          <PetitionExplanation handleLearnMoreClick={handleLearnMoreClick} />{' '}
        </>
      )}
    </>
  );
};

export default LaunchedPetitionCTA;
