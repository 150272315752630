import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { Link as StyledLink, utils } from '@makeship/core';
import { S1, S1Styles, Caption } from '../Typography';

import config from '../../../config.json';

const { routes } = config;

const BadgeRowWrapper = styled.div`
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  }
`;

const BadgeText = styled.div`
  ${S1Styles}
  margin-left: 16px;
`;

const BadgeSupportedCaption = styled(Caption)``;

const SupporterText = styled(S1)``;

type BadgeRowProps = {
  supporter: Supporter;
};

const SupporterRow: React.FC<BadgeRowProps> = ({ supporter }: BadgeRowProps) => (
  <BadgeRowWrapper>
    <BadgeText>
      {supporter.username && supporter.username !== 'Anonymous' && <SupporterText>{supporter?.username}</SupporterText>}
      <BadgeSupportedCaption>Supported {utils.getTimeFrom(supporter.firstOrderTime)} ago</BadgeSupportedCaption>
    </BadgeText>
  </BadgeRowWrapper>
);

export default SupporterRow;
