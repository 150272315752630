/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NextPage, GetServerSideProps } from 'next';
import posthog, { Properties } from 'posthog-js';
import Container from '../../components/Container';
import Head from '../../components/Head';

import { getProductByHandle } from '../../api/product';
import {
  getStage,
  parseMetafields,
  getProductIDFromShopifyGID,
  hasProductTag,
  getEvergreenInventoryState,
} from '../../utils/product';
import { ProductStage, productMOQ } from '../../types/common';

import config from '../../../config.json';
import { getFormattedAmount } from '../../utils/accounting';
import { analyticsTrackProductPageLoad, sendViewItemEvent } from '../../utils/analytics';
import { useStore } from '../../store';
import { retrievePosthogFlagBagServerSide } from '../../utils/flagging';
import { hasPetitionLaunched } from '../../components/pages/petitions/commonFunctions';
import ProductProvider, { ProductContextType } from '../../context/product';
import PetitionCountdownTimer from '../../components/pages/petitions/PetitionCountDown';
import PetitionHeader from '../../components/pages/petitions/PetitionHeader';
import PetitionCarousel from '../../components/pages/petitions/PetitionCarousel';
import PetitionInfo from '../../components/pages/petitions/PetitionInfo';
import { getPetitionPledgeCountByExternalProductID } from '../../api/storefront/petition';

declare const window: any;

const PetitionPageWrapper = styled(Container)<{ needsPadding: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ needsPadding }) => (needsPadding ? '48px 64px' : '8px 64px')};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    padding: ${({ needsPadding }) => (needsPadding ? '48px 8px' : '8px')};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: ${({ needsPadding }) => (needsPadding ? '16px 8px' : '8px')};
  }
`;

const PetitionHeaderWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    display: block;
  }
`;

const PetitionDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 120px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    flex-direction: column;
    margin-bottom: 72px;
    padding: 0 8px;
  }
`;

const PetitionInfoWrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    padding-left: 0px;
  }
`;

export type PetitionPageProps = {
  handle: string;
  product: Shopify.Product;
  pledgeCount: number;
  creatorCollectionHandle: string | null;
};

// TODO: Remove this once all affected petitions have turned into products
export const affectedPetitionsHandles = [
  'dancing-dragon-plush-plushie',
  'metin2-bonsai-seal-plushie',
  'patch-plushie',
  'nyan-plushie',
  'the-big-lebowski-plushie',
  'king-of-dokapon-kingdom-plushie',
  'soleil-plushie',
  'voltaej-plushie',
  'kirbopher-plushie',
  'waffles-the-vizsla-plushie',
  'leena-plushie',
  'madpea-noopea-plushie-1',
  'buckshot-roulette-dealer-plushie',
  'percy-poodle-plushie',
  'harpurr-kitty-warrior-plushie',
  'ogame-destroyer-plushie',
  'torrent-plushie',
];

const PetitionPage: NextPage<PetitionPageProps> = ({
  handle,
  product,
  pledgeCount,
  creatorCollectionHandle,
}: PetitionPageProps) => {
  const { state } = useStore();
  const metafields = parseMetafields(product.metafields);
  const stage = getStage(product.tags);
  const [productImages, setProductImages] = useState<Shopify.ImageEdge[]>(product.images.edges);
  const [selectedVariant, setSelectedVariant] = useState<Shopify.ProductVariant>();
  const [endDate, setEndDate] = useState<string>();
  const isAffectedPetition = affectedPetitionsHandles.includes(handle);
  const totalInventory =
    stage === ProductStage.PetitionFailed
      ? 0
      : isAffectedPetition
      ? Number(metafields?.totalsold) || Math.abs(pledgeCount || 0)
      : Math.abs(pledgeCount || 0);
  const launchDate = metafields.petitionLaunchDate;
  const moq = Number(metafields.moq) || productMOQ[product.productType] || config.defaultMOQ;

  useEffect(() => {
    analyticsTrackProductPageLoad(product, product.variants.edges[0]?.node, state.user);
    sendViewItemEvent(product, product.variants.edges[0]?.node);
    setProductImages(product.images.edges);
  }, [product, state.user]);

  useEffect(() => {
    if ((window as any).drift && (window as any).drift.api) {
      (window as any).drift.api.widget.hide();
    }
    return () => {
      if ((window as any).drift && (window as any).drift.api) {
        (window as any).drift.api.widget.show();
      }
    };
  }, []);

  useEffect(() => {
    if (!posthog.__loaded) return;

    const posthogProperties: Properties = {
      creator: metafields.creator,
      productName: product.title,
      productID: getProductIDFromShopifyGID(product.id),
      launchDate: metafields.launch,
      campaignStatus: stage,
      campaignID: metafields.campaignID,
      evergreen_inventory_state: getEvergreenInventoryState(product),
    };

    if (window && window.location) {
      posthogProperties.url = window.location.href;
    }

    posthog.capture('$pageview', posthogProperties);
    if (typeof window !== 'undefined' && window.rdt) {
      window.rdt('track', 'ViewContent', {
        ...posthogProperties,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posthog.__loaded]);

  // Used to trigger Klaviyo Viewed Page segment
  useEffect(() => {
    const _learnq = (window as any)._learnq || [];
    const page = `${process.env.NEXT_PUBLIC_DOMAIN || ''}/petitions/${handle}`;
    _learnq.push(['track', 'Viewed Page', { url: page }]);
  }, [handle]);

  useEffect(() => {
    const date = metafields.petitionEndDate || undefined;
    setEndDate(date);
  }, [metafields.petitionEndDate]);

  const productProviderValue: ProductContextType = {
    product,
    productDetails: {
      moq,
      setMOQ: (moq) => moq,
      totalInventory,
      setTotalInventory: (totalInventory) => totalInventory,
    },
    isLifestyleImageryUpdateRequired: false,
  };

  return (
    <ProductProvider value={productProviderValue}>
      <PetitionPageWrapper needsPadding={!endDate}>
        <Head
          title={product.title}
          url={`/petitions/${handle}`}
          description={product.description}
          type="product"
          currency="USD"
          image={productImages[0]?.node.transformedSrc || ''}
          price={getFormattedAmount(product.variants.edges[0].node.priceV2?.amount)}
        />
        {endDate && <PetitionCountdownTimer launchDate={launchDate} endDate={endDate} />}
        <PetitionDetailsWrapper>
          <PetitionHeaderWrapper>
            <PetitionHeader />
          </PetitionHeaderWrapper>
          <PetitionCarousel productImages={productImages} />
          <PetitionInfoWrapper>
            <PetitionInfo
              selectedVariant={selectedVariant}
              setSelectedVariant={(variant) => setSelectedVariant(variant)}
              setProductImages={setProductImages}
              hasPetitionLaunched={hasPetitionLaunched(launchDate)}
              creatorCollectionHandle={creatorCollectionHandle}
            />
          </PetitionInfoWrapper>
        </PetitionDetailsWrapper>
      </PetitionPageWrapper>
    </ProductProvider>
  );
};

export const getServerSideProps: GetServerSideProps<PetitionPageProps> = async (ctx) => {
  const { params } = ctx;
  const handle = params ? (params.handle as string) : '';

  const [product] = await Promise.all([getProductByHandle(handle)]);

  if (!product || hasProductTag(product.tags, ProductStage.Draft)) {
    return {
      notFound: true,
    };
  }

  const currentStage = getStage(product.tags);

  const petitionStages = [ProductStage.Petition, ProductStage.PetitionSuccess, ProductStage.PetitionFailed];
  if (!petitionStages.includes(currentStage)) {
    return {
      redirect: {
        destination: `/products/${handle}`,
        permanent: false,
      },
    };
  }

  const pledgeCount = await getPetitionPledgeCountByExternalProductID(getProductIDFromShopifyGID(product.id))
    .then((response) => response.data)
    .catch(() => 0);

  const flagBag = await retrievePosthogFlagBagServerSide(ctx);

  const creatorCollectionWithNoProducts = product.collections.edges.find((collection) =>
    collection.node.metafields?.find(
      (metafield) => metafield?.key === 'isCreatorCollection' && metafield?.value === 'true',
    ),
  );

  return {
    props: {
      handle,
      product,
      pledgeCount,
      flagBag,
      creatorCollectionHandle: creatorCollectionWithNoProducts?.node.handle || null,
    },
  };
};

export default PetitionPage;
