import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { S1, P2 } from '../../../Typography';
import PetitionHelpGuideModal from '../../../Modal/PetitionHelpGuideModal';

const RefundGuaranteedWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 32px;
`;

const RefundContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
`;

const LogoWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const PetitionRefundBlock: React.FC = () => {
  const [isHelpGuideModalOpen, setIsHelpGuideOpen] = React.useState(false);

  return (
    <RefundGuaranteedWrapper>
      <LogoWrapper>
        <Image src="/assets/icons/refund-icon.svg" alt="Refund Icon" width={56} height={48} layout="fixed" />
      </LogoWrapper>
      <RefundContentWrapper>
        <S1>Petition Refund Guarantee</S1>
        <P2>
          This petition did not reach its minimum goal of 200 supporters. Please allow{' '}
          <strong>4-7 business days</strong> for all pledges to be refunded.
        </P2>
      </RefundContentWrapper>
      <PetitionHelpGuideModal isOpen={isHelpGuideModalOpen} closeModal={() => setIsHelpGuideOpen(false)} />
    </RefundGuaranteedWrapper>
  );
};

export default PetitionRefundBlock;
